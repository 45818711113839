const getBackstory = async () => {
    try {
        const response = await fetch('/api/v1/oracles/get-random-backstory-introduction', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({}), // Sending an empty JSON object
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data['gm-response'];
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        return '';
    }
};

export default getBackstory;
