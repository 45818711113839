import axios from 'axios';

// Log before making the request
const logRequest = (endpoint, data) => {
    console.log(`Making request to ${endpoint} with data:`, data);
};

// Get all attributions (no token needed)
export const getAllAttributions = async () => {
    logRequest('/api/v1/orc-titles/get-all-titles', {});
    const response = await axios.get('/api/v1/orc-titles/get-all-titles');
    return response.data;
};

// Insert a new attribution
export const insertAttribution = async (attribution, authToken) => {
    const data = { ...attribution, 'auth-token': authToken }; // Use "auth-token"
    logRequest('/api/v1/orc-titles/insert-title', data); // Log the request
    const response = await axios.post('/api/v1/orc-titles/insert-title', data);
    return response.data;
};

// Update an attribution by ID
export const updateAttribution = async (id, attribution, authToken) => {
    const data = { id, ...attribution, 'auth-token': authToken }; // Use "auth-token"
    logRequest('/api/v1/orc-titles/update-title', data); // Log the request
    const response = await axios.post('/api/v1/orc-titles/update-title', data);
    return response.data;
};

// Delete an attribution by ID
export const deleteAttribution = async (id, authToken) => {
    const data = { id, 'auth-token': authToken }; // Use "auth-token"
    logRequest('/api/v1/orc-titles/delete-title', data); // Log the request
    const response = await axios.post('/api/v1/orc-titles/delete-title', data);
    return response.data;
};
