import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, ListGroup, Spinner, Button } from 'react-bootstrap';
import { getAllPageGroupsWithAllData, getAllPages } from '../api/pageApi';
import { Link } from 'react-router-dom';
import BackroomsStore from "../components/BackroomsStore";
import SimpleNavBar from "../components/SimpleNavBar";
import SitedQuote from "../components/SitedQuote";

const TableOfContents = () => {
    const [groups, setGroups] = useState({});
    const [loading, setLoading] = useState(false);
    const [pagesByGroup, setPagesByGroup] = useState({});

    // Fetch the groups and pages on component mount
    useEffect(() => {
        fetchGroupsAndPages();
    }, []);

    const fetchGroupsAndPages = async () => {
        setLoading(true);
        try {
            const groupData = await getAllPageGroupsWithAllData();
            setGroups(groupData);

            const allPages = {};
            // Fetch all pages for each group
            for (let groupPath in groupData) {
                const pages = await getAllPages(groupPath);
                allPages[groupPath] = pages.pages; // Store pages under their group path
            }
            setPagesByGroup(allPages);

        } catch (error) {
            console.error('Error fetching groups or pages:', error);
        } finally {
            setLoading(false);
        }
    };

    // Desired groups to display first, in this specific order
    const desiredOrder = ['news', 'lore', 'levels', 'entities', 'npcs'];

    // Function to get the ordered list of group paths
    const getOrderedGroupPaths = () => {
        const allGroupPaths = Object.keys(groups);

        // Filter out the groups that are in the desired order
        const orderedGroupPaths = desiredOrder.filter(group => allGroupPaths.includes(group));

        // Get the remaining groups
        const remainingGroupPaths = allGroupPaths.filter(group => !desiredOrder.includes(group));

        // Combine the ordered groups with the remaining groups
        return [...orderedGroupPaths, ...remainingGroupPaths];
    };

    return (
        <Container>
            <SimpleNavBar/>
            <img
                src="/beast_pics/smiler_in_office-e1713474334607.png"
                alt="Smiler in the Backrooms lobby"
                style={{display: 'block', margin: '0 auto', maxWidth: '100%', height: 'auto'}}
            />
            <br/><br/>
            <p>Where is everyone?</p>
            <p>What happened here?</p>
            <p>Many Backrooms levels contain more space than our universe. Each of the levels obviously held more
                humans than our universe. </p>
            <p>Now, they're empty.</p>
            <p>Many intelligent beings live in the Backrooms. Most of them don't look human. Fewer of them think
                human thoughts. Be fearful of the entities you encounter in the Backrooms.</p>
            <p>No one knows how long the Backrooms have existed. Every traveler has tales. Some of those tales go back
                to the collapse of humanity in the Backrooms.</p>
            <br/>
            <SitedQuote author="Howlin Hank Whittacker" img="/npc_pics/howlin_hank.png">
                Close your eyes and listen to the silent empty halls of the Backrooms. They'll teach you that you really
                fear your own thoughts.
            </SitedQuote>
            <br/>
            <p>The Backrooms dot Net universe documents <a href="https://twitter.com/TGeneDavis">T. Gene Davis</a>’s
                interpretation of the Backrooms urban legend. This interpretation includes stories and RPG rules based
                off Paizo's ORC licensed Pathfinder and Starfinder RPG rules.<strong><sup>*</sup></strong> T. Gene
                Davis’s
                previous works include many publications,
                including a <a href="https://amzn.to/2IInK9X">Japanese chess puzzle book</a>.</p>
            <p>Paizo Inc released their Pathfinder Second Edition Remastered RPG rules under the ORC License. The
                goal of the Backrooms RPG rules is to add rules that allow playing adventures and campaigns in the
                Backroooms using Pathfinder compatible rules, entities and classes.</p>
            <br/>
            <SitedQuote author="Gary Gygax" img="/misc_pics/gary_gygax.png">
                The secret we should never let the gamemasters know is that they don't need any rules.
            </SitedQuote>
            <br/>
            <p>Development of <em>pfOracle</em> shares this vision. Solo RPGs replace the role of gamemaster (GM) with
                that
                of an "oracle". Solo RPGs have a dedicated fan base, and many "oracle" tools to provide the element of
                surprise and excitement. The PF Oracle project investigates making AI available to act as an oracle/GM
                instead of using books, random look-up tables, and other sources to act as the solo player's
                gamemaster. pfOracle development especially seeks to allow solo play of Backrooms RPGs.</p>
            <p><em><strong><sup>*</sup></strong> T. Gene Davis and Backrooms dot Net are in no way affiliated with
                Paizo, Pathfinder, or Starfinder.</em></p>
            <p></p>
            <br/>
            <br/>


            {/* Cool Backrooms-themed title */}
            <Row className="mb-4">
                <Col>
                    <h2 className="text-center">Navigating the Backrooms</h2>
                    <p className="text-center text-muted">Unsettling Contents from Backrooms dot Net</p>
                </Col>
            </Row>

            {/* Home link at the top */}
            <Row className="mb-4">
                <Col className="d-flex justify-content-center">
                    <Link to="/">
                        <Button variant="outline-dark">Return to the Entrance (Home)</Button>
                    </Link>
                </Col>
            </Row>

            {/* Display groups and pages */}
            {loading ? (
                <div className="text-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <Row>
                    {getOrderedGroupPaths().map((groupPath) => (
                        <Col md={6} key={groupPath} className="mb-4">
                            <Card>
                                <Card.Header>
                                    <h4>{groups[groupPath]?.display_name || groupPath}</h4>
                                    <p className="text-muted">{groups[groupPath]?.group_description || ''}</p>
                                </Card.Header>
                                <Card.Body>
                                    {pagesByGroup[groupPath] && pagesByGroup[groupPath].length > 0 ? (
                                        <>
                                            <ListGroup variant="flush">
                                                {pagesByGroup[groupPath].slice(0, 5).map((page) => (
                                                    <ListGroup.Item key={page.page_id}>
                                                        <Link to={`/${groupPath}/${encodeURIComponent(page.title)}`}>
                                                            {page.title}
                                                        </Link>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                            <div className="mt-3">
                                                <Link to={`/categories/${groupPath}`}>
                                                    <Button variant="primary">
                                                        View all articles in {groups[groupPath]?.display_name || groupPath}
                                                    </Button>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <p>No pages available in this group.</p>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            )}

            {/* Home link at the bottom */}
            <Row className="mt-4">
                <Col className="d-flex justify-content-center">
                    <Link to="/">
                        <Button variant="outline-dark">Return to the Entrance (Home)</Button>
                    </Link>
                </Col>
            </Row>
            <BackroomsStore/>
        </Container>
    );
};

export default TableOfContents;
