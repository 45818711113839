import React, { useState, useEffect } from 'react';
import { useUserContext } from '../context/UserContext';
import AdminHeader from '../admin_components/AdminHeader';
import AdminTools from '../admin_components/AdminTools';
import './Admin.css'; // Importing the custom CSS

const Admin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    // Get the adminSessionToken and its setter from the UserContext
    const { adminSessionToken, setAdminSessionToken } = useUserContext();

    useEffect(() => {
        // Focus on the username input field when the component loads
        document.getElementById('username').focus();
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('/api/v1/auth/admin-login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: username,
                    password: password,
                }),
            });

            const data = await response.json();

            if (data.message === 'SUCCESS') {
                // Store the auth-token in the context
                setAdminSessionToken(data['auth-token']);
                setError('');
            } else {
                setError('Incorrect username or password');
            }
        } catch (err) {
            setError('An error occurred. Please try again.');
            console.error('Login error:', err);
        }
    };

    const handleLogout = () => {
        console.error('handleLogout was called!!!!!!!!!!');
        setUsername('');
        setPassword('');
        setAdminSessionToken(null); // Clear the session token on logout
    };

    if (adminSessionToken) {
        return (
            <div>
                <AdminHeader onLogout={handleLogout} />
                <AdminTools />
            </div>
        );
    }

    return (
        <div className="login-container">
            <div className="login-card">
                <h2 className="text-center mb-4">Admin Login</h2>
                <form onSubmit={handleLogin}>
                    <div className="form-group">
                        <label htmlFor="username">Username:</label>
                        <input
                            id="username"
                            type="text"
                            className="form-control"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password:</label>
                        <input
                            id="password"
                            type="password"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    {error && <div className="alert alert-danger mt-3">{error}</div>}
                    <button type="submit" className="btn btn-primary btn-block mt-4">Login</button>
                </form>
            </div>
        </div>
    );
};

export default Admin;
