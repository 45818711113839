import React from 'react';

const AdminHeader = ({ onLogout }) => {
    return (
        <header className="admin-header">
            <button onClick={onLogout}>Logout</button>
        </header>
    );
};

export default AdminHeader;
