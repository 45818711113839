import React, { useState, useEffect, useRef } from 'react';
import './SmilerEscape.css';

// Import sound effects
import backgroundMusic from '../assets/background-music.mp3';//Sound Effect from <a href="https://pixabay.com/sound-effects/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=33079">Pixabay</a>
import jumpSound from '../assets/jump-sound.mp3';//Sound Effect from <a href="https://pixabay.com/sound-effects/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=87726">Pixabay</a>
import speedBoostSound from '../assets/speed-boost-sound.mp3';//Sound Effect from <a href="https://pixabay.com/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=43790">Pixabay</a>
import gameOverSound from '../assets/game-over-sound.mp3';//Sound Effect by <a href="https://pixabay.com/users/blendertimer-9538909/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=116359">Daniel Roberts</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=116359">Pixabay</a>

// Import images
import playerImage from '../assets/player-character.png';
import smilerImage from '../assets/smiler.png';
import obstacleImage from '../assets/obstacle.png';
import speedBoostImage from '../assets/speed-boost.png';
import jumpscareImage from '../assets/jumpscare.png';

const SmilerEscape = () => {
    // Audio references
    const backgroundMusicRef = useRef(new Audio(backgroundMusic));
    const jumpSoundRef = useRef(new Audio(jumpSound));
    const speedBoostSoundRef = useRef(new Audio(speedBoostSound));
    const gameOverSoundRef = useRef(new Audio(gameOverSound));

    const GAME_WIDTH = 800;
    const GAME_HEIGHT = 600;
    const PLAYER_WIDTH = 50;
    const PLAYER_HEIGHT = 50;
    const END_DISTANCE = 800; // Reduced to 800

    const [playerPosition, setPlayerPosition] = useState(50);
    const [isJumping, setIsJumping] = useState(false);
    const [obstacles, setObstacles] = useState([]);
    const [speedBoosts, setSpeedBoosts] = useState([]);
    const [gameSpeed, setGameSpeed] = useState(7); // Increased to 7
    const [playerSpeed, setPlayerSpeed] = useState(2); // Increased to 2
    const [distanceTravelled, setDistanceTravelled] = useState(0);

    const [smilerPosition, setSmilerPosition] = useState(-400); // Start further off-screen
    const [smilerSpeed, setSmilerSpeed] = useState(1); // Reduced initial speed
    const [gameOver, setGameOver] = useState(false);
    const [gameWon, setGameWon] = useState(false);

    const playerPositionRef = useRef(playerPosition);
    const isJumpingRef = useRef(isJumping);
    const gameSpeedRef = useRef(gameSpeed);
    const smilerPositionRef = useRef(smilerPosition);
    const smilerSpeedRef = useRef(smilerSpeed);
    const gameOverRef = useRef(gameOver);
    const playerSpeedRef = useRef(playerSpeed);
    const distanceTravelledRef = useRef(distanceTravelled);

    const [showJumpscare, setShowJumpscare] = useState(true);

    useEffect(() => {
        playerPositionRef.current = playerPosition;
    }, [playerPosition]);

    useEffect(() => {
        isJumpingRef.current = isJumping;
    }, [isJumping]);

    useEffect(() => {
        gameSpeedRef.current = gameSpeed;
    }, [gameSpeed]);

    useEffect(() => {
        smilerPositionRef.current = smilerPosition;
    }, [smilerPosition]);

    useEffect(() => {
        smilerSpeedRef.current = smilerSpeed;
    }, [smilerSpeed]);

    useEffect(() => {
        gameOverRef.current = gameOver;
    }, [gameOver]);

    useEffect(() => {
        playerSpeedRef.current = playerSpeed;
    }, [playerSpeed]);

    useEffect(() => {
        distanceTravelledRef.current = distanceTravelled;
    }, [distanceTravelled]);

    // Play background music
    useEffect(() => {
        const bgMusic = backgroundMusicRef.current;
        bgMusic.loop = true;
        bgMusic.volume = 0.5;
        bgMusic.play();

        return () => {
            bgMusic.pause();
            bgMusic.currentTime = 0;
        };
    }, []);

// Handle keyboard input
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (gameOverRef.current || gameWon) return;

            if (e.key === 'ArrowLeft') {
                setPlayerPosition((prev) => Math.max(prev - 5, 0));
            } else if (e.key === 'ArrowRight') {
                setPlayerPosition((prev) => Math.min(prev + 5, 100));
            } else if (e.code === 'Space') {
                if (!isJumpingRef.current) {
                    setIsJumping(true);
                    jumpSoundRef.current.currentTime = 0;
                    jumpSoundRef.current.play();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Prevent scrolling on arrow keys and spacebar
        window.addEventListener('keydown', preventDefaultArrowKeys);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keydown', preventDefaultArrowKeys);
        };
    }, [gameOver, gameWon]);


    // Prevent default behavior for arrow keys and spacebar
    const preventDefaultArrowKeys = (e) => {
        if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Space'].includes(e.code)) {
            e.preventDefault();
        }
    };

    // Handle jumping mechanic
    useEffect(() => {
        if (isJumping) {
            const jumpDuration = 1000; // Jump duration in milliseconds
            const timer = setTimeout(() => {
                setIsJumping(false);
            }, jumpDuration);
            return () => clearTimeout(timer);
        }
    }, [isJumping]);

    // Generate obstacles
    useEffect(() => {
        if (gameOver || gameWon) return;

        const obstacleInterval = setInterval(() => {
            const obstacle = {
                id: Date.now() + Math.random(),
                type: 'obstacle',
                left: Math.random() * 100, // Random horizontal position (0% to 100%)
                bottom: GAME_HEIGHT, // Start from the top of the game container
                width: 50, // Fixed width for consistency
                height: 50, // Fixed height for consistency
                hit: false, // To track if collision has been handled
            };
            setObstacles((prevObstacles) => [...prevObstacles, obstacle]);
        }, 2000); // Generate an obstacle every 2 seconds

        return () => clearInterval(obstacleInterval);
    }, [gameOver, gameWon]);

    // Generate speed boosts
    useEffect(() => {
        if (gameOver || gameWon) return;

        const speedBoostInterval = setInterval(() => {
            const speedBoost = {
                id: Date.now() + Math.random(),
                type: 'speedBoost',
                left: Math.random() * 100, // Random horizontal position
                bottom: GAME_HEIGHT, // Start from the top
                width: 30,
                height: 30,
                collected: false,
            };
            setSpeedBoosts((prevBoosts) => [...prevBoosts, speedBoost]);
        }, 5000); // Generate a speed boost every 5 seconds

        return () => clearInterval(speedBoostInterval);
    }, [gameOver, gameWon]);

    // Move obstacles and speed boosts, handle collision detection
    useEffect(() => {
        if (gameOver || gameWon) return;

        const gameInterval = setInterval(() => {
            // Update distance travelled
            setDistanceTravelled((prevDistance) => prevDistance + playerSpeedRef.current);

            // Check if player has reached the endpoint
            if (distanceTravelledRef.current >= END_DISTANCE) {
                setGameWon(true);
            }

            // Move obstacles
            setObstacles((prevObstacles) => {
                const newObstacles = prevObstacles
                    .map((obstacle) => {
                        const newObstacle = {
                            ...obstacle,
                            bottom: obstacle.bottom - gameSpeedRef.current, // Move down
                        };

                        // Collision detection with player
                        const playerLeft =
                            (playerPositionRef.current / 100) * GAME_WIDTH - PLAYER_WIDTH / 2;
                        const playerRight = playerLeft + PLAYER_WIDTH;
                        const playerBottom = isJumpingRef.current ? 150 : 10; // From CSS
                        const playerTop = playerBottom + PLAYER_HEIGHT;

                        const obstacleLeft = (obstacle.left / 100) * GAME_WIDTH;
                        const obstacleRight = obstacleLeft + obstacle.width;
                        const obstacleBottom = newObstacle.bottom;
                        const obstacleTop = obstacleBottom + obstacle.height;

                        const isColliding =
                            playerRight > obstacleLeft &&
                            playerLeft < obstacleRight &&
                            playerTop > obstacleBottom &&
                            playerBottom < obstacleTop;

                        if (isColliding && !isJumpingRef.current && !obstacle.hit) {
                            newObstacle.hit = true;
                            setGameSpeed((prevSpeed) => Math.max(prevSpeed - 2, 1)); // Reduce speed
                            setTimeout(() => {
                                setGameSpeed(5); // Restore speed after 2 seconds
                            }, 2000);
                        }

                        return newObstacle;
                    })
                    .filter((obstacle) => obstacle.bottom + obstacle.height > 0); // Remove off-screen obstacles

                return newObstacles;
            });

            // Move speed boosts
            setSpeedBoosts((prevBoosts) => {
                const newBoosts = prevBoosts
                    .map((boost) => {
                        const newBoost = {
                            ...boost,
                            bottom: boost.bottom - gameSpeedRef.current, // Move down
                        };

                        // Collision detection with player
                        const playerLeft =
                            (playerPositionRef.current / 100) * GAME_WIDTH - PLAYER_WIDTH / 2;
                        const playerRight = playerLeft + PLAYER_WIDTH;
                        const playerBottom = isJumpingRef.current ? 150 : 10;
                        const playerTop = playerBottom + PLAYER_HEIGHT;

                        const boostLeft = (boost.left / 100) * GAME_WIDTH;
                        const boostRight = boostLeft + boost.width;
                        const boostBottom = newBoost.bottom;
                        const boostTop = boostBottom + boost.height;

                        const isColliding =
                            playerRight > boostLeft &&
                            playerLeft < boostRight &&
                            playerTop > boostBottom &&
                            playerBottom < boostTop;

                        if (isColliding && !boost.collected) {
                            newBoost.collected = true;
                            setPlayerSpeed(3); // Increase player speed
                            speedBoostSoundRef.current.currentTime = 0;
                            speedBoostSoundRef.current.play();
                            setTimeout(() => {
                                setPlayerSpeed(1); // Restore speed after duration
                            }, 5000); // Speed boost lasts for 5 seconds
                        }

                        return newBoost;
                    })
                    .filter((boost) => boost.bottom + boost.height > 0 && !boost.collected); // Remove off-screen or collected boosts

                return newBoosts;
            });
        }, 50); // Update every 50ms

        return () => clearInterval(gameInterval);
    }, [gameOver, gameWon]);

    // Increase Smiler's speed over time
    useEffect(() => {
        if (gameOver || gameWon) return;

        const speedIncreaseInterval = setInterval(() => {
            setSmilerSpeed((prevSpeed) => prevSpeed + 0.02); // Slower increment
        }, 1000);

        return () => clearInterval(speedIncreaseInterval);
    }, [gameOver, gameWon]);

    // Move Smiler towards player
    useEffect(() => {
        if (gameOver || gameWon) return;

        const smilerMovementInterval = setInterval(() => {
            setSmilerPosition((prevPosition) => prevPosition + smilerSpeedRef.current);

            // Collision detection with player
            const playerBottom = isJumpingRef.current ? 150 : 10;
            const playerTop = playerBottom + PLAYER_HEIGHT;
            const smilerBottom = smilerPositionRef.current;
            const smilerTop = smilerBottom + 100; // Smiler's height

            if (
                smilerTop >= playerBottom &&
                smilerBottom <= playerTop
            ) {
                // Smiler has reached the player
                setGameOver(true);
                gameOverSoundRef.current.currentTime = 0;
                gameOverSoundRef.current.play();
            }
        }, 50);

        return () => clearInterval(smilerMovementInterval);
    }, [gameOver, gameWon]);

    // Handle game over jumpscare
    useEffect(() => {
        if (gameOver) {
            // Hide jumpscare after the animation duration
            const timer = setTimeout(() => {
                setShowJumpscare(false);
            }, 1000); // Match the animation duration (1s)
            return () => clearTimeout(timer);
        } else {
            setShowJumpscare(true); // Reset when game restarts
        }
    }, [gameOver]);

    const handleRestart = () => {
        // Reset all game state to initial values
        setPlayerPosition(50);
        setIsJumping(false);
        setObstacles([]);
        setSpeedBoosts([]);
        setGameSpeed(5);
        setPlayerSpeed(1);
        setDistanceTravelled(0);
        setSmilerPosition(-200);
        setSmilerSpeed(2);
        setGameOver(false);
        setGameWon(false);

        // Reset audio
        backgroundMusicRef.current.currentTime = 0;
        backgroundMusicRef.current.play();
    };

    // Calculate progress percentage for the endpoint
    const progressPercentage = (distanceTravelled / END_DISTANCE) * 100;

    return (
        <div className="game-container">
            {/* Back to Top Button */}
            <button
                className="back-to-top"
                onClick={() => (window.location.href = '/')}
            >
                Back to Games List
            </button>

            {/* Progress Bar */}
            <div className="progress-bar">
                <div
                    className="progress"
                    style={{ width: `${Math.min(progressPercentage, 100)}%` }}
                ></div>
            </div>

            {/* Endpoint Door */}
            {progressPercentage >= 80 && (
                <div className="endpoint-door" style={{ bottom: '600px' }}></div>
            )}

            {/* Player */}
            <div
                className={`player ${isJumping ? 'jumping' : ''}`}
                style={{
                    left: `${playerPosition}%`,
                    backgroundImage: `url(${playerImage})`,
                }}
            ></div>

            {/* Obstacles */}
            {obstacles.map((obstacle) => (
                <div
                    key={obstacle.id}
                    className="obstacle"
                    style={{
                        left: `${obstacle.left}%`,
                        bottom: `${obstacle.bottom}px`,
                        width: `${obstacle.width}px`,
                        height: `${obstacle.height}px`,
                        backgroundImage: `url(${obstacleImage})`,
                    }}
                ></div>
            ))}

            {/* Speed Boosts */}
            {speedBoosts.map((boost) => (
                <div
                    key={boost.id}
                    className="speed-boost"
                    style={{
                        left: `${boost.left}%`,
                        bottom: `${boost.bottom}px`,
                        width: `${boost.width}px`,
                        height: `${boost.height}px`,
                        backgroundImage: `url(${speedBoostImage})`,
                    }}
                ></div>
            ))}

            {/* Smiler */}
            <div
                className="smiler"
                style={{
                    bottom: `${smilerPosition}px`,
                    backgroundImage: `url(${smilerImage})`,
                }}
            ></div>

            {/* Game Over Screen */}
            {gameOver && (
                <div className="game-over-screen">
                    {showJumpscare && (
                        <div
                            className="jumpscare"
                            style={{ backgroundImage: `url(${jumpscareImage})` }}
                        ></div>
                    )}
                    <h1>Game Over</h1>
                    <button onClick={handleRestart}>Restart</button>
                </div>
            )}

            {/* Game Won Screen */}
            {gameWon && (
                <div className="game-won-screen">
                    <h1>You Escaped!</h1>
                    <button onClick={handleRestart}>Play Again</button>
                </div>
            )}
        </div>
    );
};

export default SmilerEscape;
