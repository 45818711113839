import React, { useState, useEffect, useCallback } from 'react';
import { useUserContext } from './context/UserContext'; // Import the context hook
import './App.css';

const SystemInfo = () => {
    const { adminSessionToken } = useUserContext(); // Get the auth token from the context
    const [systemInfo, setSystemInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchSystemInfo = useCallback(async () => {
        try {
            setLoading(true);
            const response = await fetch('/api/v1/system/get-system-info', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'auth-token': adminSessionToken, // Pass the auth token in the body
                }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setSystemInfo(data);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }, [adminSessionToken]); // Depend on adminSessionToken

    useEffect(() => {
        fetchSystemInfo();
    }, [fetchSystemInfo]);

    if (loading) {
        return <div>Loading system information...</div>;
    }

    if (error) {
        return <div>Error fetching system information: {error.message}</div>;
    }

    // Helper function to format the output
    const formatOutput = (output) => {
        return output.split('\n').map((line, index) => (
            <pre key={index}>{line}</pre>
        ));
    };

    return (
        <div className="system-info">
            <h2>System Information</h2>
            <div className="system-section">
                <h3>Disk Usage (df -h)</h3>
                {systemInfo.df ? formatOutput(systemInfo.df) : <p>No data available.</p>}
            </div>
            <div className="system-section">
                <h3>Swap Information (swapon --show)</h3>
                {systemInfo.swapon ? formatOutput(systemInfo.swapon) : <p>No data available.</p>}
            </div>
            <div className="system-section">
                <h3>Top Processes</h3>
                {systemInfo.top ? formatOutput(systemInfo.top) : <p>No data available.</p>}
            </div>
            <button onClick={fetchSystemInfo} className="refresh-button mt-3">Refresh</button>
        </div>
    );
};

export default SystemInfo;
