import React from 'react';

const AttributionsList = ({ attributions, onDelete, onEdit }) => {
    const getYearPublished = (year_published) => {
        if (!year_published) {
            return 'Unknown';
        }
        if (typeof year_published === 'number') {
            return year_published;
        }
        const date = new Date(year_published);
        return isNaN(date.getUTCFullYear()) ? 'Unknown' : date.getUTCFullYear();
    };

    // New function to handle delete with confirmation
    const handleDelete = (id) => {
        const confirmed = window.confirm('Are you sure you want to delete this attribution?');
        if (confirmed) {
            onDelete(id);
        }
    };

    return (
        <div className="attributions-list">
            {attributions.map((attribution) => (
                <div key={attribution.id} className="attribution-item">
                    <h2>{attribution.title || 'Untitled'}</h2>
                    <p>
                        <strong>Year Published:</strong>{' '}
                        {getYearPublished(attribution.year_published)}
                    </p>
                    <p>
                        <strong>Attributions:</strong>{' '}
                        {attribution.attributions || 'None'}
                    </p>

                    {/* Display Image Link URL */}
                    {attribution.image_link && (
                        <>
                            <p>
                                <strong>Image Link URL:</strong>{' '}
                                <a
                                    href={attribution.image_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {attribution.image_link}
                                </a>
                            </p>
                            {/* Display Image */}
                            <img
                                src={attribution.image_link}
                                alt={`${attribution.title || 'Untitled'} cover`}
                            />
                        </>
                    )}

                    {/* Display Purchase Link URL */}
                    {attribution.purchase_link && (
                        <p>
                            <strong>Purchase Link URL:</strong>{' '}
                            <a
                                href={attribution.purchase_link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {attribution.purchase_link}
                            </a>
                        </p>
                    )}

                    <button className="btn btn-primary mr-2" onClick={() => onEdit(attribution)}>Edit</button>
                    <button className="btn btn-danger" onClick={() => handleDelete(attribution.id)}>Delete</button>
                </div>
            ))}
        </div>
    );
};

export default AttributionsList;
