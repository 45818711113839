import React from 'react';

const DropdownField = ({ label, options, selected, onChange }) => {
    return (
        <div className="dropdown-field">
            <label>{label}:</label>
            <select
                value={selected || ''}
                onChange={(e) => onChange(e.target.value)}
                title={options.find(option => option.value === selected)?.tooltip || ''}
            >
                <option value="" disabled>Select {label}</option>
                {options.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.value}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DropdownField;
