import React from 'react';

const HitPointsSection = ({ ancestry, classSelection, stats }) => {
    const ancestryHp = ancestry?.hitPoints || 0;
    const classHp = classSelection?.hitPoints || 0;
    const conHp = stats.CON;

    const totalHp = ancestryHp + classHp + conHp;

    return (
        <div className="hitpoints-section">
            <h2>Hit Points</h2>
            <table>
                <thead>
                <tr>
                    <th>Ancestry</th>
                    <th>Class</th>
                    <th>CON</th>
                    <th>Final</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{ancestryHp}</td>
                    <td>{classHp}</td>
                    <td>{conHp}</td>
                    <td>{totalHp}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default HitPointsSection;
