import React from 'react';

const About = () => {
    return (
        <div className="about-page">
            <h1>About</h1>
            <p>Welcome to pfOracle! This application serves as a versatile tool for enhancing your tabletop RPG experience.</p>
            <p>Our dice roller, character management, and AI-driven game mastering are all designed to make your sessions more engaging and enjoyable.</p>

            <p>Dice icons by Lorc, Delapouite & contributors, sourced from https://game-icons.net/, licensed under CC BY 3.0.</p>
            <p>Dice Rolling Sound Effect from <a href="https://pixabay.com/sound-effects/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=102706">Pixabay</a></p>

        </div>
    );
};

export default About;
