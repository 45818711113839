import React from 'react';

const StatsSection = ({ ancestry, heritage, background, classSelection, stats, setStats }) => {
    const handleStatChange = (stat) => {
        if (stats[stat] === 2) return; // Example: Prevent assigning more than 2 free points

        setStats({
            ...stats,
            [stat]: stats[stat] + 1
        });
    };

    return (
        <div className="stats-section">
            <h2>Stats</h2>
            <table className="table table-bordered">
                <thead>
                <tr>
                    <th>Base</th>
                    <th>Ancestry</th>
                    <th>Background</th>
                    <th>Class</th>
                    <th>Finishing (+2)</th>
                    <th>Final</th>
                </tr>
                </thead>
                <tbody>
                {Object.keys(stats).map((stat) => (
                    <tr key={stat}>
                        <td>{stat}</td>
                        <td>{ancestry?.stats?.[stat] || 0}</td>
                        <td>{background?.stats?.[stat] || 0}</td>
                        <td>{classSelection?.stats?.[stat] || 0}</td>
                        <td>
                            <button onClick={() => handleStatChange(stat)}>+</button>
                        </td>
                        <td>{(ancestry?.stats?.[stat] || 0) + (background?.stats?.[stat] || 0) + (classSelection?.stats?.[stat] || 0) + stats[stat]}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default StatsSection;
