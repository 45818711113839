import React, { useState, useEffect, useCallback } from 'react';
import { useUserContext } from './context/UserContext'; // Import the context hook
import logo from './logo.svg';
import './App.css';

const HealthStatus = () => {
    const { adminSessionToken } = useUserContext(); // Get the auth token from the context
    const [healthData, setHealthData] = useState(null);
    const [aiStatus, setAiStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchHealthData = useCallback(async () => {
        try {
            setLoading(true);
            const response = await fetch('/api/health', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'auth-token': adminSessionToken, // Pass the auth token in the body
                }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setHealthData(data);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }, [adminSessionToken]); // Depend on adminSessionToken to make sure it's available

    const checkAiStatus = useCallback(async () => {
        try {
            const response = await fetch('/api/v1/oracles/ask-public-oracle', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: '', intro: '', asked: 'ping' }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setAiStatus(data['gm-response'] ? 'Online' : 'Offline');
        } catch (error) {
            setAiStatus('Offline');
        }
    }, []);

    const refreshStatus = useCallback(() => {
        fetchHealthData();
        checkAiStatus();
    }, [fetchHealthData, checkAiStatus]);

    useEffect(() => {
        refreshStatus();
    }, [refreshStatus]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div className="health-status">
            <img src={logo} className="App-logo" alt="logo" style={{ width: '50px', height: '50px' }} />
            <h2>Health Status</h2>
            <p><strong>Time:</strong> {healthData.time}</p>
            <p><strong>Up Since:</strong> {healthData['up-since']}</p>
            <p><strong>Clojure Kit Server:</strong> {healthData['clojure-kit-server']}</p>
            <p><strong>Redis Connected:</strong> {healthData['redis-connected'] ? 'Yes' : 'No'}</p>
            <p><strong>MySQL Connected:</strong> {healthData['mysql-connected'] ? 'Yes' : 'No'}</p>
            <p><strong>API Endpoints Working:</strong> Yes</p>
            <p><strong>React Working:</strong> Yes</p>
            <p><strong>AI Status:</strong> {aiStatus || 'Checking...'}</p>
            <button onClick={refreshStatus} className="refresh-button mt-3">Refresh</button>
        </div>
    );
};

export default HealthStatus;
