import React, { useState, useEffect } from 'react';

const FeatsSection = ({ ancestry, background, classSelection }) => {
    const [feats, setFeats] = useState([]);
    const [selectedFeat, setSelectedFeat] = useState('');

    useEffect(() => {
        const newFeats = [];
        if (ancestry) {
            newFeats.push(...ancestry.feats);
        }
        if (background) {
            newFeats.push(...background.feats);
        }
        if (classSelection) {
            newFeats.push(...classSelection.feats);
        }
        setFeats(newFeats);
    }, [ancestry, background, classSelection]);

    const handleFeatChange = (event) => {
        setSelectedFeat(event.target.value);
    };

    return (
        <div className="feats-section">
            <h2>Feats</h2>
            <table className="table table-bordered">
                <thead>
                <tr>
                    <th>Feat</th>
                </tr>
                </thead>
                <tbody>
                {feats.map((feat, index) => (
                    <tr key={index}>
                        <td>{feat}</td>
                    </tr>
                ))}
                <tr>
                    <td>
                        <select value={selectedFeat} onChange={handleFeatChange}>
                            <option value="" disabled>Select a Feat</option>
                            {/* Assuming these are the available feats to choose from */}
                            <option value="Toughness">Toughness</option>
                            <option value="Alertness">Alertness</option>
                            <option value="Weapon Focus">Weapon Focus</option>
                            <option value="Power Attack">Power Attack</option>
                            <option value="Natural Medicine">Natural Medicine</option>
                            <option value="Stealthy">Stealthy</option>
                        </select>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default FeatsSection;
