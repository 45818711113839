// src/api/pageApi.js

// Fetch the list of all pages for a specific group
export const getAllPageGroups = async () => {
    const response = await fetch(`/api/v1/pages/get-all-page-groups`);
    if (!response.ok) {
        throw new Error('Failed to load page groups');
    }
    return await response.json();  // Ensure the response is parsed as JSON
};


// Fetch the list of all pages for a specific group
export const getAllPageGroupsWithAllData = async () => {
    const response = await fetch(`/api/v1/pages/get-all-page-groups-with-all-data`);
    if (!response.ok) {
        throw new Error('Failed to load page groups');
    }
    return await response.json();  // Ensure the response is parsed as JSON
};


export const getAllPages = async (group) => {
    const response = await fetch(`/api/v1/pages/get-all-pages?group=${group}`);
    //console.log("group="+group);
    if (!response.ok) {
        throw new Error('Failed to load pages');
    }
    return await response.json();  // Ensure the response is parsed as JSON
};


// Delete a specific page using POST request, specifying the group and page name, and passing adminSessionToken
export const deletePage = async (page, group, adminSessionToken) => {
    const response = await fetch('/api/v1/pages/delete-page', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            pagename: page,
            group: group,
            'auth-token': adminSessionToken, // Include the auth-token in the body
        }),
    });

    if (!response.ok) {
        throw new Error('Failed to delete page');
    }
};

