import React, { useState, useEffect, useRef } from 'react';
import { Button, Spinner, Container, Row, Col } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { saveToAPI, loadFromAPI, getEditorKey } from '../api/contentApi'; // Import getEditorKey
import { useUserContext } from '../context/UserContext';

const EditorComponent = ({ group }) => {
    const { adminSessionToken } = useUserContext();

    const { pagename } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [htmlContent, setHtmlContent] = useState('');
    const [loading, setLoading] = useState(false);
    const createPage = searchParams.get('createPage') === 'true';
    const editorRef = useRef(null);

    // New state variables for apiKey and its loading status
    const [apiKey, setApiKey] = useState(null);
    const [apiKeyLoading, setApiKeyLoading] = useState(true);

    useEffect(() => {
        // Fetch the TinyMCE API key from the server when the component mounts
        const fetchApiKey = async () => {
            try {
                const key = await getEditorKey(adminSessionToken);
                setApiKey(key);
            } catch (error) {
                console.error('Failed to load TinyMCE API key:', error);
                alert('Failed to load editor configuration.');
            } finally {
                setApiKeyLoading(false);
            }
        };

        fetchApiKey();
    }, [adminSessionToken]);

    useEffect(() => {
        loadContent();
    }, [pagename, group]);

    const loadContent = async () => {
        setLoading(true);
        try {
            console.log("loadContent ... group=" + group + "; pagename=" + pagename);
            const content = await loadFromAPI(group, pagename);
            setHtmlContent(content);
            if (createPage) {
                setSearchParams({});
            }
        } catch (error) {
            if (error.message.includes('404')) {
                if (createPage) {
                    await handleCreateNewPage();
                } else {
                    alert('Failed to load content.');
                }
            } else {
                alert('Failed to load content.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleCreateNewPage = async () => {
        try {
            const newContent = '<p>New page content</p>';
            await saveToAPI(newContent, group, pagename, adminSessionToken);
            setHtmlContent(newContent);
            alert('New page created successfully!');
            setSearchParams({});
        } catch (error) {
            alert('Failed to create new page.');
        }
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            const contentToSave = htmlContent;
            await saveToAPI(contentToSave, group, pagename, adminSessionToken);
            alert('Content saved successfully!');
        } catch (error) {
            alert('Failed to save content.');
        } finally {
            setLoading(false);
        }
    };

    const handleRevert = () => {
        const confirmed = window.confirm('Are you sure you want to revert? All unsaved changes will be lost.');
        if (confirmed) {
            loadContent();
        }
    };

    const handleViewPage = () => {
        const url = `/${group}/${encodeURIComponent(pagename)}`;
        window.open(url, '_blank');
    };

    const handleCancel = () => {
        navigate('/admin/pages');
    };

    // If the API key is still loading, display a spinner
    if (apiKeyLoading) {
        return (
            <Container className="mt-4">
                <Row className="justify-content-center">
                    <Col lg={10} md={12}>
                        <div className="text-center">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading Editor...</span>
                            </Spinner>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Container className="mt-4">
            <Row className="justify-content-center">
                <Col lg={10} md={12}>
                    <h2 className="text-center mb-4">Editing: {group}/{pagename}</h2>
                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : (
                        <>
                            <Editor
                                apiKey={apiKey}  // Use the apiKey from state
                                onInit={(evt, editor) => editorRef.current = editor}
                                value={htmlContent}
                                onEditorChange={(content, editor) => setHtmlContent(content)}
                                init={{
                                    height: 500,
                                    menubar: 'file edit view insert format tools table help',
                                    plugins: 'advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table help wordcount hr',
                                    toolbar:
                                        'code | h2 h3 | bold italic | link image | styles | \
                                        alignleft aligncenter alignright alignjustify | hr | forecolor backcolor | \
                                        bullist numlist outdent indent | underline strikethrough | \
                                        table media | removeformat | charmap',
                                    block_formats: 'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3; Preformatted=pre',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                    valid_elements: '*[*]',
                                    extended_valid_elements: '*[*]',
                                    entity_encoding: 'raw',
                                    verify_html: false,
                                    cleanup: false,
                                    keep_styles: true,
                                    skin: 'oxide',
                                    content_css: 'default',
                                }}
                            />

                            <div className="d-flex justify-content-end mt-3">
                                <Button
                                    onClick={handleSave}
                                    variant="primary"
                                    disabled={loading}
                                    className="me-2"
                                >
                                    Save
                                </Button>
                                <Button
                                    onClick={handleRevert}
                                    variant="secondary"
                                    className="me-2"
                                    disabled={loading}
                                >
                                    Revert
                                </Button>
                                <Button
                                    onClick={handleViewPage}
                                    variant="info"
                                    disabled={loading}
                                >
                                    View
                                </Button>
                                <Button
                                    onClick={handleCancel}
                                    variant="danger"
                                    className="ms-2"
                                    disabled={loading}
                                >
                                    Groups
                                </Button>
                            </div>
                        </>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default EditorComponent;
