import React, { useState } from 'react';
import DropdownField from '../components/DropdownField';
import StatsSection from '../components/StatsSection';
import HitPointsSection from '../components/HitPointsSection';
import SkillsTrainingSection from '../components/SkillsTrainingSection';
import FeatsSection from '../components/FeatsSection';
import { ancestries, heritages, backgrounds, classes } from '../utils/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/CharacterWorksheet.css';

const CharacterWorksheet = () => {
    const [ancestry, setAncestry] = useState(null);
    const [heritage, setHeritage] = useState(null);
    const [background, setBackground] = useState(null);
    const [classSelection, setClassSelection] = useState(null);
    const [stats, setStats] = useState({
        STR: 0,
        DEX: 0,
        CON: 0,
        INT: 0,
        WIS: 0,
        CHA: 0
    });

    return (
        <div className="character-worksheet">
            <h1>Character Creation Worksheet</h1>

            <DropdownField
                label="Ancestry"
                options={ancestries}
                selected={ancestry}
                onChange={setAncestry}
            />
            <DropdownField
                label="Heritage"
                options={heritages}
                selected={heritage}
                onChange={setHeritage}
            />
            <DropdownField
                label="Background"
                options={backgrounds}
                selected={background}
                onChange={setBackground}
            />
            <DropdownField
                label="Class"
                options={classes}
                selected={classSelection}
                onChange={setClassSelection}
            />

            <StatsSection
                ancestry={ancestry}
                heritage={heritage}
                background={background}
                classSelection={classSelection}
                stats={stats}
                setStats={setStats}
            />

            <HitPointsSection
                ancestry={ancestry}
                classSelection={classSelection}
                stats={stats}
            />

            <SkillsTrainingSection
                ancestry={ancestry}
                background={background}
                classSelection={classSelection}
            />

            <FeatsSection
                ancestry={ancestry}
                background={background}
                classSelection={classSelection}
            />
        </div>
    );
};

export default CharacterWorksheet;
