import React, { useEffect, useRef } from 'react';

const ResponseDisplay = ({ responses }) => {
    const responseContainerRef = useRef(null);

    useEffect(() => {
        if (responseContainerRef.current) {
            responseContainerRef.current.scrollTop = responseContainerRef.current.scrollHeight;
        }
    }, [responses]);

    return (
        <div ref={responseContainerRef} style={{ maxHeight: '300px', overflowY: 'auto', border: '1px solid #ccc', padding: '10px', backgroundColor: '#fdf5e6' }}>
            {responses.map((response, index) => (
                <div key={index} dangerouslySetInnerHTML={{ __html: response }} />
            ))}
        </div>
    );
};

export default ResponseDisplay;
