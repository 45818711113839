import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, ListGroup, Spinner } from 'react-bootstrap';
import { getAllPages, getAllPageGroupsWithAllData } from '../api/pageApi';
import SimpleNavBar from "../components/SimpleNavBar";
import BackroomsStore from "../components/BackroomsStore";

const CategoryPage = () => {
    const { category } = useParams();
    const [pages, setPages] = useState([]);
    const [groupInfo, setGroupInfo] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchCategoryPages();
    }, [category]);

    const fetchCategoryPages = async () => {
        setLoading(true);
        try {
            // Fetch all pages in the category
            const pagesData = await getAllPages(category);
            setPages(pagesData.pages);

            // Fetch all groups with all data
            const allGroups = await getAllPageGroupsWithAllData();

            // Extract the group info for the current category
            let groupInfo = allGroups[category];

            // If allGroups is an array or groupInfo is undefined, find the group manually
            if (!groupInfo && Array.isArray(allGroups)) {
                groupInfo = allGroups.find(group => group.group_path === category);
            }

            setGroupInfo(groupInfo || {});
        } catch (error) {
            console.error('Error fetching category pages:', error);
        } finally {
            setLoading(false);
        }
    };

    // Scroll to the top when loading finishes
    useEffect(() => {
        if (!loading) {
            window.scrollTo(0, 0);
        }
    }, [loading]);

    return (
        <Container>
            <SimpleNavBar />
            <h2>{groupInfo?.display_name || category}</h2>
            <p>{groupInfo?.group_description || ''}</p>
            {loading ? (
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            ) : (
                <ListGroup>
                    {pages.map((page) => (
                        <ListGroup.Item key={page.page_id}>
                            <Link to={`/${category}/${encodeURIComponent(page.title)}`}>
                                {page.title}
                            </Link>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            )}
            <BackroomsStore />
        </Container>
    );
};

export default CategoryPage;
