import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Howl } from 'howler';

// Import die images
import d4Image from '../assets/d4.svg';
import d6Image from '../assets/d6.svg';
import d8Image from '../assets/d8.svg';
import d10Image from '../assets/d10.svg';
import d20Image from '../assets/d20.svg';
import defaultDieImage from '../assets/default-die.svg'; // Placeholder for custom dice

// Import sound file
import rollSoundFile from '../assets/rolling-dice-2-102706.mp3';
import SimpleNavBar from "./SimpleNavBar";
import {Container} from "react-bootstrap";
import BackroomsStore from "./BackroomsStore";

const dieImages = {
    4: d4Image,
    6: d6Image,
    8: d8Image,
    10: d10Image,
    20: d20Image,
};

const DiceRoller = () => {
    const [diceExpression, setDiceExpression] = useState('1d20+2'); // Updated default expression
    const [rollResults, setRollResults] = useState([]);
    const [rolling, setRolling] = useState(false);
    const [numSides, setNumSides] = useState(20); // Updated default die sides
    const [total, setTotal] = useState(null); // New state for total

    // Preload sound effect
    const rollSound = new Howl({
        src: [rollSoundFile],
        volume: 1.0,
    });

    // Handle dice roll
    const rollDice = (expr = diceExpression) => {
        const parsed = parseDiceExpression(expr);
        if (!parsed) return alert('Invalid dice expression!');
        const { numDice, numSides, modifier } = parsed;

        setNumSides(numSides); // Update current die sides
        setRolling(true);
        rollSound.play();

        // Simulate dice roll animation duration
        setTimeout(() => {
            const results = [];
            let sum = 0;
            for (let i = 0; i < numDice; i++) {
                const roll = Math.floor(Math.random() * numSides) + 1;
                results.push(roll);
                sum += roll;
            }
            sum += modifier; // Apply modifier to the sum
            setRollResults(results);
            setTotal(sum); // Update total
            setRolling(false);
        }, 1000); // Animation duration
    };

    // Parse dice expression (e.g., '3d6+2' or '1d10-1')
    const parseDiceExpression = (expr) => {
        const match = expr.match(/^(\d+)d(\d+)([+-]\d+)?$/i);
        if (!match) return null;
        const numDice = parseInt(match[1]);
        const numSides = parseInt(match[2]);
        const modifier = match[3] ? parseInt(match[3]) : 0;
        return { numDice, numSides, modifier };
    };

    // Get appropriate die image
    const getDieImage = (sides) => {
        return dieImages[sides] || defaultDieImage;
    };

    return (
        <Container>
            <SimpleNavBar/>

            <div className="dice-roller">

                {/* Quick Roll Icons */}
                <div className="quick-roll-container">
                    {[4, 6, 8, 10, 20].map((sides) => (
                        <img
                            key={sides}
                            src={getDieImage(sides)}
                            alt={`d${sides}`}
                            className="quick-roll-die"
                            onClick={() => {
                                if (!rolling) {
                                    const expr = `1d${sides}`;
                                    setDiceExpression(expr);
                                    rollDice(expr);
                                }
                            }}
                        />
                    ))}
                </div>

                <div className="input-group">
                    <input
                        type="text"
                        value={diceExpression}
                        onChange={(e) => setDiceExpression(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' && !rolling && rollDice()}
                        placeholder="Enter dice expression (e.g., 2d8+3)"
                    />
                    <button onClick={() => rollDice()} disabled={rolling}>
                        Roll
                    </button>
                </div>
                <div className="dice-container">
                    {rolling ? (
                        <motion.img
                            src={getDieImage(numSides)}
                            alt={`d${numSides}`}
                            className="dice-animation"
                            animate={{rotate: 360}}
                            transition={{duration: 1, loop: Infinity}}
                        />
                    ) : (
                        <>
                            {rollResults.map((result, index) => (
                                <div key={index} className="dice-result">
                                    <img src={getDieImage(numSides)} alt={`d${numSides}`} className="die-image"/>
                                    <motion.div
                                        className="die-value"
                                        initial={{opacity: 0}}
                                        animate={{opacity: 1}}
                                        transition={{duration: 0.5}}
                                    >
                                        {result}
                                    </motion.div>
                                </div>
                            ))}
                            {/* Display total if more than one die or a modifier is present */}
                            {(rollResults.length > 1 || total !== null) && (
                                <div className="total">
                                    Total: {total}
                                </div>
                            )}
                        </>
                    )}
                </div>
                <style jsx>{`
                    .dice-roller {
                        text-align: center;
                        max-width: 300px;
                        margin: auto;
                    }

                    .quick-roll-container {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 20px;
                    }

                    .quick-roll-die {
                        width: 40px;
                        height: 40px;
                        margin: 0 5px;
                        cursor: pointer;
                    }

                    .input-group {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 20px;
                    }

                    input {
                        width: 150px;
                        padding: 5px;
                        margin-right: 10px;
                    }

                    button {
                        padding: 5px 10px;
                    }

                    .dice-container {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                    }

                    .dice-result {
                        display: flex;
                        align-items: center;
                        margin: 5px;
                    }

                    .die-image {
                        width: 40px;
                        height: 40px;
                        margin-right: 5px;
                    }

                    .die-value {
                        font-size: 24px;
                    }

                    .dice-animation {
                        width: 80px;
                        height: 80px;
                    }

                    .total {
                        width: 100%;
                        text-align: center;
                        font-size: 24px;
                        margin-top: 10px;
                        font-weight: bold;
                    }
                `}</style>
            </div>
            <div
                style={{minHeight: '20em'}}  // Ensure a minimum height
            />
            <p><strong>Instructions:</strong> Click a die for a quick roll. Or, type in standard die roll notation (i.e. 3d10+2) for a complex roll.</p>

            <BackroomsStore />
        </Container>
    );
};

export default DiceRoller;
