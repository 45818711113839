import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Spinner, Button } from 'react-bootstrap';
import TextArea from '../components/TextArea';
import ResponseDisplay from '../components/ResponseDisplay';
import getBackstory from '../utils/getBackstory';
import BackroomsStore from "../components/BackroomsStore";
import SimpleNavBar from "../components/SimpleNavBar";

const GameMasterOracle = () => {
    const [backstory, setBackstory] = useState('');
    const [question, setQuestion] = useState('');
    const [backstoryVisible, setBackstoryVisible] = useState(true);
    const [token, setToken] = useState('');
    const [responses, setResponses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [aiAvailable, setAiAvailable] = useState(null); // New state variable for AI availability

    const fetchBackstory = useCallback(async () => {
        setLoading(true);
        try {
            const initialBackstory = await getBackstory();
            setBackstory(initialBackstory);
        } finally {
            setLoading(false);
        }
    }, []);

    const checkAiStatus = useCallback(async () => {
        try {
            const response = await fetch('/api/v1/oracles/ask-public-oracle', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: '', intro: '', asked: 'ping' }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setAiAvailable(data['gm-response'] ? true : false);
        } catch (error) {
            setAiAvailable(false);
        }
    }, []);

    useEffect(() => {
        const initialize = async () => {
            await checkAiStatus();
            if (aiAvailable) {
                fetchBackstory();
            }
        };
        initialize();
    }, [checkAiStatus, aiAvailable, fetchBackstory]);

    const interactWithGM = async () => {
        if (!question.trim()) {
            return; // Do nothing if the question is empty
        }

        const payload = {
            token: token,
            intro: backstory,
            asked: question,
        };

        setLoading(true);
        try {
            const response = await fetch('/api/v1/oracles/ask-public-oracle', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setToken(data.token);
            setResponses((prevResponses) => [...prevResponses, data['gm-response']]);
            setBackstoryVisible(false);
            setBackstory(''); // Clear backstory text area
            setQuestion(''); // Clear question text area
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent default Enter key behavior
            interactWithGM();
        }
    };

    // Conditional rendering based on AI availability
    if (aiAvailable === null) {
        // AI availability is still being checked
        return (
            <Container className="text-center mt-5">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Checking AI availability...</span>
                </Spinner>
                <p>Checking AI availability...</p>
            </Container>
        );
    } else if (aiAvailable === false) {
        // AI is not available
        return (
            <Container className="text-center mt-5">
                <SimpleNavBar/>
                <h1>pfOracle</h1>
                <p>Uh-oh! Our Game Master seems to have wandered off into the Backrooms. 🕵️‍♂️</p>
                <p>Don't worry. They'll find their way back soon.</p>
                <br/>
                <br/>
                <br/>
                <br/>
                <BackroomsStore/>
            </Container>
        );
    }

    // AI is available; render the normal UI
    return (
        <Container>
            <SimpleNavBar />
            <div className="mt-5 position-relative">
                <h1>pfOracle</h1>
                <p>The Backrooms are vast, containing all sorts of worlds. Your Solo Adventure starts now!</p>
                {backstoryVisible && (
                    <Row className="mb-3">
                        <Col>
                            <TextArea
                                placeholder="Explain the situation to the GM"
                                value={backstory}
                                onChange={setBackstory}
                                maxChars={500}
                                rows={10}
                                style={{ backgroundColor: '#f0f0f0', height: '200px' }}
                            />
                        </Col>
                    </Row>
                )}
                <Row className="mb-3">
                    <Col>
                        <TextArea
                            placeholder="Enter your player's question or action"
                            value={question}
                            onChange={setQuestion}
                            maxChars={150}
                            rows={2}
                            onKeyPress={handleKeyPress}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            variant="primary"
                            onClick={interactWithGM}
                            disabled={loading}
                        >
                            {loading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span className="sr-only">Loading...</span>
                                </>
                            ) : (
                                'Interact'
                            )}
                        </Button>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <ResponseDisplay responses={responses} />
                    </Col>
                </Row>
                <BackroomsStore />
            </div>
        </Container>
    );
};

export default GameMasterOracle;
