import React from 'react';
import { Image } from 'react-bootstrap';

const SitedQuote = ({ author, img, children }) => {
    return (
        <div className="d-flex flex-column flex-md-row align-items-center my-4">
            <Image
                src={img}
                alt={author}
                roundedCircle
                className="mb-3 mb-md-0 mr-md-3"
                style={{ width: '80px', height: '80px', objectFit: 'cover' }}
            />
            <div className="text-center text-md-left">
                <blockquote className="blockquote mb-0">
                    <p><strong>"{children}"</strong></p>
                    <footer className="blockquote-footer">{author}</footer>
                </blockquote>
            </div>
        </div>
    );
};

export default SitedQuote;
