export const ancestries = [
    { value: 'Dwarf', tooltip: 'Dwarves are stout and strong. (PC1)', skills: ['Athletics'], feats: ['Weapon Focus'] },
    { value: 'Elf', tooltip: 'Elves are nimble and keenly attuned to nature. (PC1)', skills: ['Stealth'], feats: ['Alertness'] },
    { value: 'Gnome', tooltip: 'Humans are versatile and diverse. (PC1)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Halfling', tooltip: 'Humans are versatile and diverse. (PC1)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Human', tooltip: 'Humans are versatile and diverse. (PC1)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Leshy', tooltip: 'Humans are versatile and diverse. (PC1)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Orc', tooltip: 'Humans are versatile and diverse. (PC1)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Catfolk', tooltip: 'Humans are versatile and diverse. (PC2)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Hobgoblin', tooltip: 'Humans are versatile and diverse. (PC2)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Kholo', tooltip: 'Humans are versatile and diverse. (PC2)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Kobold', tooltip: 'Humans are versatile and diverse. (PC2)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Lizardfolk', tooltip: 'Humans are versatile and diverse. (PC2)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Ratfolk', tooltip: 'Humans are versatile and diverse. (PC2)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Tengu', tooltip: 'Humans are versatile and diverse. (PC2)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Tripkee', tooltip: 'Humans are versatile and diverse. (PC2)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Dokkaebi Goblin', tooltip: 'Humans are versatile and diverse. (TXCG)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Hungerseed', tooltip: 'Humans are versatile and diverse. (TXCG)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Kijimuna Gnome', tooltip: 'Humans are versatile and diverse. (TXCG)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Kitsune', tooltip: 'Humans are versatile and diverse. (TXCG)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Nagaji', tooltip: 'Humans are versatile and diverse. (TXCG)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Samsaran', tooltip: 'Humans are versatile and diverse. (TXCG)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Sarangay', tooltip: 'Humans are versatile and diverse. (TXCG)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Sprite', tooltip: 'Humans are versatile and diverse. (TXCG)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Tanuki', tooltip: 'Humans are versatile and diverse. (TXCG)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Tsukumogami Poppet', tooltip: 'Humans are versatile and diverse. (TXCG)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Wayang', tooltip: 'Humans are versatile and diverse. (TXCG)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Yaksha', tooltip: 'Humans are versatile and diverse. (TXCG)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Yaoguai', tooltip: 'Humans are versatile and diverse. (TXCG)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Athamaru', tooltip: 'Humans are versatile and diverse. (HOTW)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Awakened Animal', tooltip: 'Humans are versatile and diverse. (HOTW)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Centaur', tooltip: 'Humans are versatile and diverse. (HOTW)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Merfolk', tooltip: 'Humans are versatile and diverse. (HOTW)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Minotaur', tooltip: 'Humans are versatile and diverse. (HOTW)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Surki', tooltip: 'Humans are versatile and diverse. (HOTW)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Android', tooltip: 'Humans are versatile and diverse. (SPR)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Barathu', tooltip: 'Humans are versatile and diverse. (SPR)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Kasatha', tooltip: 'Humans are versatile and diverse. (SPR)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Lashunta', tooltip: 'Humans are versatile and diverse. (SPR)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Pahtra', tooltip: 'Humans are versatile and diverse. (SPR)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Shirren', tooltip: 'Humans are versatile and diverse. (SPR)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Skittermander', tooltip: 'Humans are versatile and diverse. (SPR)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Vesk', tooltip: 'Humans are versatile and diverse. (SPR)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Ysoki', tooltip: 'Humans are versatile and diverse. (SPR)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Borai', tooltip: 'Humans are versatile and diverse. (SPR)', skills: ['Acrobatics'], feats: ['Toughness'] },
    { value: 'Prismeni', tooltip: 'Humans are versatile and diverse. (SPR)', skills: ['Acrobatics'], feats: ['Toughness'] },
];

export const heritages = [
    { value: 'Ancient-Blooded', tooltip: 'Enhanced abilities.', skills: [], feats: [] },
    { value: 'Woodland', tooltip: 'Highly attuned to nature.', skills: [], feats: [] }
];

export const backgrounds = [
    { value: 'Noble', tooltip: 'Raised in wealth.', skills: ['Deception'], feats: ['Natural Medicine'] },
    { value: 'Soldier', tooltip: 'Trained in combat.', skills: ['Athletics'], feats: ['Power Attack'] }
];

export const classes = [
    { value: 'Bard::Maestro', tooltip: 'Focus on arts and inspiration.', skills: ['Performance'], feats: ['Stealthy'] },
    { value: 'Druid::Storm', tooltip: 'Commands the power of storms.', skills: ['Nature'], feats: [] }
];
