import React, { useState, useEffect } from 'react';

const SkillsTrainingSection = ({ ancestry, background, classSelection }) => {
    const [skills, setSkills] = useState([]);
    const [selectedSkill, setSelectedSkill] = useState('');

    useEffect(() => {
        const newSkills = [];
        if (ancestry) {
            newSkills.push(...ancestry.skills);
        }
        if (background) {
            newSkills.push(...background.skills);
        }
        if (classSelection) {
            newSkills.push(...classSelection.skills);
        }
        setSkills(newSkills);
    }, [ancestry, background, classSelection]);

    const handleSkillChange = (event) => {
        setSelectedSkill(event.target.value);
    };

    return (
        <div className="skills-training-section">
            <h2>Skills Training</h2>
            <table className="table table-bordered">
                <thead>
                <tr>
                    <th>Skill</th>
                </tr>
                </thead>
                <tbody>
                {skills.map((skill, index) => (
                    <tr key={index}>
                        <td>{skill}</td>
                    </tr>
                ))}
                <tr>
                    <td>
                        <select value={selectedSkill} onChange={handleSkillChange}>
                            <option value="" disabled>Select a Skill</option>
                            {/* Assuming these are the available skills to choose from */}
                            <option value="Acrobatics">Acrobatics</option>
                            <option value="Arcana">Arcana</option>
                            <option value="Athletics">Athletics</option>
                            <option value="Deception">Deception</option>
                            <option value="Nature">Nature</option>
                            <option value="Stealth">Stealth</option>
                        </select>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default SkillsTrainingSection;
