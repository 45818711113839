import React, { useState, useEffect } from 'react';

const AttributionForm = ({ onSubmit, editingAttribution }) => {
    const [title, setTitle] = useState('');
    const [yearPublished, setYearPublished] = useState('');
    const [attributions, setAttributions] = useState('');
    const [imageLink, setImageLink] = useState('');
    const [purchaseLink, setPurchaseLink] = useState('');

    useEffect(() => {
        if (editingAttribution) {
            setTitle(editingAttribution.title);
            setYearPublished(editingAttribution.year_published.toString());
            setAttributions(editingAttribution.attributions);
            setImageLink(editingAttribution.image_link || '');
            setPurchaseLink(editingAttribution.purchase_link || '');
        } else {
            clearForm();
        }
    }, [editingAttribution]);

    const clearForm = () => {
        setTitle('');
        setYearPublished('');
        setAttributions('');
        setImageLink('');
        setPurchaseLink('');
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        const newAttribution = {
            title,
            year_published: parseInt(yearPublished, 10),
            attributions,
            image_link: imageLink,
            purchase_link: purchaseLink
        };

        onSubmit(newAttribution);
        clearForm();
    };

    return (
        <form onSubmit={handleSubmit} className="attribution-form">
            <div>
                <label>Title:</label>
                <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>Year Published:</label>
                <input
                    type="number"
                    value={yearPublished}
                    onChange={(e) => setYearPublished(e.target.value)}
                    required
                    min="1900"
                    max="2100"
                />
            </div>
            <div>
                <label>Attributions:</label>
                <textarea
                    value={attributions}
                    onChange={(e) => setAttributions(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>Image Link:</label>
                <input
                    type="text"
                    value={imageLink}
                    onChange={(e) => setImageLink(e.target.value)}
                />
            </div>
            <div>
                <label>Purchase Link:</label>
                <input
                    type="text"
                    value={purchaseLink}
                    onChange={(e) => setPurchaseLink(e.target.value)}
                />
            </div>
            <button type="submit">{editingAttribution ? 'Update' : 'Create'} Attribution</button>
        </form>
    );
};

export default AttributionForm;
