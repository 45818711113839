// src/context/UserContext.js
import React, { createContext, useState, useContext } from 'react';

// Create the context
const UserContext = createContext(null);

// Create a hook to use the context
export const useUserContext = () => {
    return useContext(UserContext);
};

// User context provider to wrap the entire app
export const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState({
        username: 'admin',
        isLoggedIn: true,
        // Add more user-specific data if needed
    });

    // State to store the admin session token
    const [adminSessionToken, setAdminSessionToken] = useState(null);

    return (
        <UserContext.Provider value={{ user, setUser, adminSessionToken, setAdminSessionToken }}>
            {children}
        </UserContext.Provider>
    );
};
