import React, { useEffect, useState } from 'react';
import { useUserContext } from '../context/UserContext';
import AttributionForm from './AttributionForm';
import AttributionsList from './AttributionsList';
import {
    getAllAttributions,
    deleteAttribution,
    insertAttribution,
    updateAttribution
} from '../services/attributionsApi';
import './AdminAttributionsEditor.css';
import SimpleNavBar from "../components/SimpleNavBar";

const AdminAttributionsEditor = () => {
    const { adminSessionToken } = useUserContext();
    const [attributions, setAttributions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editingAttribution, setEditingAttribution] = useState(null);

    // Function to process attributions
    const processAttribution = (attr) => ({
        ...attr,
        year_published:
            typeof attr.year_published === 'string'
                ? new Date(attr.year_published).getUTCFullYear()
                : attr.year_published,
    });

    // Fetch all attributions on load
    useEffect(() => {
        const fetchAttributions = async () => {
            try {
                const data = await getAllAttributions();
                // Process data to ensure consistent formats
                const processedData = data.map(processAttribution);
                setAttributions(processedData);
            } catch (err) {
                setError('Failed to load attributions.');
            } finally {
                setLoading(false);
            }
        };

        fetchAttributions();
    }, []);

    const handleDelete = async (id) => {
        try {
            await deleteAttribution(id, adminSessionToken);
            setAttributions(attributions.filter((attr) => attr.id !== id));
        } catch (err) {
            setError('Failed to delete attribution.');
        }
    };

    const handleEdit = (attribution) => {
        setEditingAttribution(attribution);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleFormSubmit = async (newAttribution) => {
        try {
            if (editingAttribution) {
                // Update attribution
                await updateAttribution(
                    editingAttribution.id,
                    newAttribution,
                    adminSessionToken
                );
            } else {
                // Insert new attribution
                await insertAttribution(newAttribution, adminSessionToken);
            }
            // Refetch all attributions to ensure data consistency
            const data = await getAllAttributions();
            const processedData = data.map(processAttribution);
            setAttributions(processedData);
            setEditingAttribution(null);
        } catch (err) {
            setError('Failed to submit attribution.');
            console.error(
                'Error during form submission:',
                err.response ? err.response.data : err
            );
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="admin-attributions-editor">
            <SimpleNavBar />

            <h1>Attributions Editor</h1>
            <AttributionForm
                onSubmit={handleFormSubmit}
                editingAttribution={editingAttribution}
            />
            <AttributionsList
                attributions={attributions}
                onDelete={handleDelete}
                onEdit={handleEdit}
            />
        </div>
    );
};

export default AdminAttributionsEditor;
