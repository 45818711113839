import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './AdminTools.css';
import { FaToolbox, FaChartLine } from 'react-icons/fa'; // Importing icons
import HealthStatus from '../HealthStatus';
import SystemInfo from "../SystemInfo";

const AdminTools = () => {
    return (
        <div className="admin-tools-container">
            <h3 className="mb-4">Admin Tools</h3>
            <div className="tools-list">
                <Link to="/admin/pages" className="tool-link">
                    <FaToolbox className="tool-icon"/> Page Management
                </Link>
                <Link to="/admin/attributions_editor" className="tool-link">
                    <FaToolbox className="tool-icon"/> Attributes Editor
                </Link>
            </div>
            <div className="stats-and-health-container mt-5">
                <div className="site-stats-column">
                    <h4><FaChartLine className="stats-icon"/> Site Stats</h4>
                    <p>Placeholder for site statistics.</p>
                </div>
                <div className="health-status-column">
                    <HealthStatus/>
                </div>
            </div>
            <div className="system-info-container mt-5">
                <SystemInfo />
            </div>

        </div>
    );
};

export default AdminTools;
