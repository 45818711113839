import React from 'react';
import { Form } from 'react-bootstrap';

const TextArea = ({ placeholder, value, onChange, maxChars, rows, onKeyPress, style }) => {
    const handleChange = (e) => {
        const text = e.target.value;
        if (!maxChars || text.length <= maxChars) {
            onChange(text);
        }
    };

    return (
        <Form.Group controlId={`textarea-${placeholder}`}>
            <Form.Control
                as="textarea"
                rows={rows}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                onKeyPress={onKeyPress}
                style={style}
            />
        </Form.Group>
    );
};

export default TextArea;
