import { useUserContext } from '../context/UserContext';  // Import the hook
import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Table, Dropdown, DropdownButton, Container, Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getAllPages, getAllPageGroupsWithAllData, deletePage } from '../api/pageApi';
import SimpleNavBar from "../components/SimpleNavBar"; // Import API functions

const PageManagement = () => {
    const { user, adminSessionToken } = useUserContext();  // Access the user data

    const [pages, setPages] = useState([]);  // Pages should be an array, not an object
    const [groups, setGroups] = useState({});  // State for groups (map of group data)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [pageToDelete, setPageToDelete] = useState(null);
    const [newPageName, setNewPageName] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('wiki'); // Default group
    const navigate = useNavigate();

    useEffect(() => {
        loadPageGroups();  // Fetch groups on mount
    }, []);

    useEffect(() => {
        if (selectedGroup) {
            loadPages();
        }
    }, [selectedGroup]);

    const loadPageGroups = async () => {
        try {
            const data = await getAllPageGroupsWithAllData();  // Use the detailed group data
            if (data) {
                setGroups(data);
                setSelectedGroup(Object.keys(data)[0]);  // Select the first group by default
            } else {
                console.error('No groups found');
            }
        } catch (error) {
            console.error('Error loading page groups:', error);
        }
    };

    const loadPages = async () => {
        try {
            const data = await getAllPages(selectedGroup);  // Fetch pages for the selected group
            const { pages: fetchedPages } = data;
            if (fetchedPages && fetchedPages.length > 0) {
                setPages(fetchedPages);
            } else {
                setPages([]);  // Set pages to empty if no pages are found
                console.error('No pages found');
            }
        } catch (error) {
            console.error('Error loading pages:', error);
        }
    };

    const handleDeletePage = async () => {
        try {
            // Ensure you're passing the page title (pagename) and the group's path (not display name)
            await deletePage(pageToDelete.title, selectedGroup, adminSessionToken); // Use title instead of page_id
            setShowDeleteModal(false);
            loadPages(); // Refresh the list of pages after deletion
        } catch (error) {
            console.error('Error deleting page:', error);
        }
    };


    const handleCreatePage = () => {
        const encodedTitle = encodeURIComponent(newPageName);
        navigate(`/admin/${selectedGroup}/${encodedTitle}?createPage=true`);  // Pass the createPage flag
    };

    const handleNavigate = (action, page) => {
        const encodedTitle = encodeURIComponent(page.title);  // URL encode the page title for SEO-friendly URL
        const path = action === 'edit' ? `/admin/${selectedGroup}/${encodedTitle}` : `/${selectedGroup}/${encodedTitle}`;
        navigate(path);
    };

    const selectedGroupDetails = groups[selectedGroup] || {};

    return (
        <Container className="mt-4">
            <SimpleNavBar />

            {/* Move dropdown and create form to the top */}
            <Row className="mb-4">
                <Col md={4} className="mb-2">
                    <DropdownButton id="dropdown-basic-button" title="Select Page Group">
                        {Object.keys(groups).map((group) => (
                            <Dropdown.Item
                                key={group}
                                onClick={() => setSelectedGroup(group)}
                            >
                                {groups[group].display_name || group}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Col>

                <Col md={8}>
                    <Form inline className="d-flex justify-content-end">
                        <Form.Group controlId="formNewPage">
                            <Form.Control
                                type="text"
                                placeholder={`New ${selectedGroupDetails.display_name || selectedGroup} Page Name`}
                                value={newPageName}
                                onChange={(e) => setNewPageName(e.target.value)}
                                className="me-2"
                            />
                        </Form.Group>{' '}
                        <Button
                            variant="success"
                            onClick={handleCreatePage}
                            disabled={!newPageName}
                        >
                            Create New Page
                        </Button>
                    </Form>
                </Col>
            </Row>

            {/* Group details and description */}
            <Row className="mb-4">
                <Col>
                    <h1 className="text-center">Page Management - {selectedGroupDetails.display_name || selectedGroup}</h1>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <p><strong>Description:</strong> {selectedGroupDetails.group_description || 'No description available.'}</p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">Existing Pages</h5>
                        </Card.Header>
                        <Card.Body>
                            {pages.length > 0 ? (
                                <Table striped bordered hover responsive>
                                    <thead>
                                    <tr>
                                        <th>Page Name</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {pages.map((page) => (
                                        <tr key={page.page_id}>
                                            <td>{page.title}</td>
                                            <td>
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    onClick={() => handleNavigate('edit', page)}
                                                    className="me-2"
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    variant="info"
                                                    size="sm"
                                                    onClick={() => handleNavigate('view', page)}
                                                    className="me-2"
                                                >
                                                    View
                                                </Button>
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() => {
                                                        setPageToDelete(page);  // Set the full page object for deletion
                                                        setShowDeleteModal(true);
                                                    }}
                                                >
                                                    Delete
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <p>No pages found in this group.</p>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Delete Confirmation Modal */}
            <Modal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete the page "{pageToDelete?.title}" from the {selectedGroupDetails.display_name || selectedGroup} group? This action cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeletePage}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default PageManagement;
