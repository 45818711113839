// Import necessary hooks and components
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';
import { useUserContext } from '../context/UserContext';
import BackroomsStore from "../components/BackroomsStore";
import SimpleNavBar from "../components/SimpleNavBar";  // Adjust the path as needed

const PageViewerComponent = ({ group }) => {
    const { pagename } = useParams();  // Dynamic group and page name from the URL
    const [htmlContent, setHtmlContent] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();  // For redirecting to the edit page

    // Access adminSessionToken from UserContext
    const { adminSessionToken } = useUserContext();

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);

        const fetchContent = async () => {
            setLoading(true);
            try {
                const response = await fetch(`/api/v1/pages/load-content?group=${group}&pagename=${pagename}`);
                const data = await response.json();
                setHtmlContent(data.content || 'Content not found.');  // Use 'content' from the API response
            } catch (error) {
                console.error('Error loading page content:', error);
                setHtmlContent('Error loading content.');
            }
            setLoading(false);
        };

        fetchContent();
    }, [group, pagename]);

    useEffect(() => {
        // Apply styling to all images after the HTML content is injected
        if (htmlContent && !loading) {
            const images = document.querySelectorAll('img');
            images.forEach((img) => {
                img.style.display = 'block';
                img.style.margin = '0 auto';
                img.style.maxWidth = '100%';
                img.style.height = 'auto';
            });
        }
    }, [htmlContent, loading]);

    const handleEdit = () => {
        // Redirect to the admin editor for the current page
        navigate(`/admin/${group}/${encodeURIComponent(pagename)}`);
    };

    return (
        <Container>
            <SimpleNavBar />

            {/* Conditionally render Edit Button */}
            {adminSessionToken && (
                <div className="d-flex justify-content-end mt-3">
                    <Button variant="primary" onClick={handleEdit}>Edit Page</Button>
                </div>
            )}

            {/* Page Title */}
            <h1 className="mt-3">{pagename.replace('-', ' ')}</h1>

            {/* Page Content */}
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div
                    style={{ minHeight: '20em' }}  // Ensure a minimum height equivalent to 6 lines of text
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                />
            )}
            <BackroomsStore />
        </Container>
    );
};

export default PageViewerComponent;
