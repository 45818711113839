import React from 'react';
import './store_assets/BackroomsStore.css';

// Adjust the import path based on your directory structure
import backroomsTshirtImage from './store_assets/backrooms-dark-entity-tshirt.png';
import backroomsHatImage from './store_assets/backrooms-smiler-hat.png';
import backroomsCarWreckTshirtImage from './store_assets/backrooms-car-wreck-tshirt.png';
import backroomsUnstableTshirtImage from './store_assets/backrooms-unstable-reality-tshirt.png';
import backroomsCarWreckMugImage from './store_assets/backrooms-car-wreck-mug.png';
import backroomsHallTshirtImage from './store_assets/backrooms-hall-tshirt.png';

const BackroomsStore = () => {
    const items = [
        {
            image: backroomsTshirtImage,
            link: 'https://www.redbubble.com/i/t-shirt/Backrooms-Dark-Entity-by-backroomsdotnet/165297994.1YYVU',
        },
        {
            image: backroomsHatImage,
            link: 'https://www.redbubble.com/i/hat/Backrooms-Smiler-Entity-by-backroomsdotnet/161604565.R256Z',
        },
        {
            image: backroomsCarWreckTshirtImage,
            link: 'https://www.redbubble.com/i/t-shirt/Backrooms-Car-Wreck-by-backroomsdotnet/165298080.1YYVU',
        },
        {
            image: backroomsUnstableTshirtImage,
            link: 'https://www.redbubble.com/i/t-shirt/Backrooms-Caution-Sign-by-backroomsdotnet/161630161.D681C',
        },
        {
            image: backroomsCarWreckMugImage,
            link: 'https://www.redbubble.com/i/mug/Car-in-Backrooms-by-backroomsdotnet/161211096.9Q0AD',
        },
        {
            image: backroomsHallTshirtImage,
            link: 'https://www.redbubble.com/i/t-shirt/Backrooms-endless-yellow-walls-and-liminal-spaces-by-backroomsdotnet/137201145.SWO0S',
        },
        // Stubbed items (import their images similarly)
        // {
        //   image: item2Image,
        //   link: 'https://example.com/product2',
        // },
        // {
        //   image: item3Image,
        //   link: 'https://example.com/product3',
        // },
    ];

    return (
        <div className="backrooms-store">
            {/* Call to Action */}
            <div className="store-header">
                <h2>Unlock the Secrets of the Backrooms!</h2>
                <p>Exclusive merchandise awaits. Dive deeper into the unknown—get yours now!</p>
            </div>

            {/* Merchandise Items */}
            <div className="store-items">
                {items.map((item, index) => (
                    <a key={index} href={item.link} target="_blank" rel="noopener noreferrer">
                        <img src={item.image} alt={`Item ${index + 1}`} className="store-item-image" />
                    </a>
                ))}
            </div>

            {/* Visual Separator */}
            <div className="store-separator"></div>
        </div>
    );
};

export default BackroomsStore;
