import React, { useEffect, useState } from 'react';
import './Attributions.css';
import SimpleNavBar from '../components/SimpleNavBar';
import BackroomsStore from '../components/BackroomsStore';
import axios from 'axios';
import { Container } from 'react-bootstrap';

const Attributions = () => {
    const [attributions, setAttributions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch all ORC titles from the API
    useEffect(() => {
        const fetchAttributions = async () => {
            try {
                const response = await axios.get('/api/v1/orc-titles/get-all-titles');
                const data = response.data;

                // Process data to ensure consistent formats
                const processedData = data.map((attr) => ({
                    ...attr,
                    year_published: new Date(attr.year_published), // Store Date object
                }));

                // Sort the attributions based on year_published in UTC
                const sortedAttributions = processedData.sort((a, b) => {
                    return a.year_published.getTime() - b.year_published.getTime();
                });

                setAttributions(sortedAttributions);
                setLoading(false);
            } catch (err) {
                setError('Failed to load attributions');
                setLoading(false);
            }
        };

        fetchAttributions();
    }, []);

    if (loading) {
        return (
            <Container>
                <div>Loading...</div>
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <div>Error: {error}</div>
            </Container>
        );
    }

    return (
        <Container>
            <SimpleNavBar />
            <div className="container">
                <h1>ORC Notice</h1>
                <p>
                    Backrooms dot Net and pfOracle contain original content by T. Gene Davis and T. Gene Davis's
                    interpretations of
                    community lore based on the urban legends of the Backrooms. This product is licensed under the ORC
                    License located at the Library of Congress at TX 9-307-067 and&nbsp;
                    <a href="https://paizo.com/orclicense">paiso.com/orclicense</a>.
                </p>

                <h1>Attributions</h1>
                <p>
                    This section lists all the Paizo ORC Licensed titles and their associated attributions that are being
                    referenced in the development of pfOracle and Backrooms dot Net rules.{' '}
                    <a href="https://backrooms.net">Backrooms dot Net</a> and&nbsp;
                    <a href="/pforacle">pfOracle</a> are not affiliated with Paizo, Pathfinder, or Starfinder.
                </p>

                <div className="attributions-list">
                    {attributions.map((attribution) => (
                        <div key={attribution.id} className="attribution-item">
                            <div className="attribution-content">
                                {attribution.image_link && (
                                    <img
                                        src={attribution.image_link}
                                        alt={`${attribution.title} Cover`}
                                        className="attribution-image"
                                    />
                                )}
                                <div className="attribution-details">
                                    <h2>
                                        {attribution.purchase_link ? (
                                            <a href={attribution.purchase_link} target="_blank"
                                               rel="noopener noreferrer">
                                                {attribution.title}
                                            </a>
                                        ) : (
                                            attribution.title
                                        )}
                                    </h2>
                                    <p>
                                        <strong>Year Published:</strong>{' '}
                                        {attribution.year_published.getUTCFullYear()}
                                    </p>
                                    <p>
                                        <strong>Attributions:</strong> {attribution.attributions}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <h1>Reserved Material</h1>
                <p>
                    Reserved Material elements in Backrooms dot Net and pfOracle include all elements designated as Reserved Material
                    under the ORC License. To avoid confusion, such items include: All trademarks, registered
                    trademarks, proper nouns (characters, powers, deities, levels, sub-levels, locations, etc., as well as all
                    adjectives, names, titles, and descriptive terms derived from proper nouns), artworks, characters,
                    dialogue, powers, levels, sub-levels, locations, organizations, plots, storylines, and trade dress.
                </p>

                <h1>Expressly Designated Licensed Material</h1>
                <p>
                    Backrooms dot Net and pfOracle contain no Expressly Designated Licensed Material.
                </p>

            </div>
            <BackroomsStore/>
        </Container>
    );
};

export default Attributions;
